import React from "react";
import PropTypes from "prop-types";
import QuranChapter from "../templates/QuranChapterTemplate";
import { graphql } from "gatsby";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Index(props: any) {
  return <QuranChapter data={props.data} />;
}

Index.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object
};

export default Index;

export const quranQuery = graphql`
  query QuranInfoJson($slug: Int! = 1) {
    allTranslationJson(filter: { chapterNumber: { eq: $slug } }) {
      nodes {
        verses {
          verseNumber
          verseString
          verseStringB
        }
        nameEnglish
        nameTrans
        nameArabic
        chapterNumber
      }
    }
    allQuranInfoJson {
      edges {
        node {
          nVerses
          nameEnglish
          nameTrans
          nameArabic
        }
      }
    }
  }
`;
